import { ClientType } from 'types';
import { RtkTagTypes, baseApi } from './baseApi';

export const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<ClientType[], void>({
      query: () => ({
        url: '/clients',
        method: 'GET',
      }),
      providesTags: [RtkTagTypes.Clients],
    }),
    addClient: builder.mutation<boolean, FormData>({
      query: (client) => ({
        url: '/clients',
        method: 'POST',
        body: client,
        validateStatus: (response, body) => {
          if (response.status === 500) {
            return false;
          }
          return response.ok;
        },
      }),
      invalidatesTags: [RtkTagTypes.Clients],
    }),
    deleteClient: builder.mutation<boolean, string>({
      query: (id) => ({
        url: `/clients/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RtkTagTypes.Clients],
    }),
  }),
});

export const { useGetClientsQuery, useAddClientMutation, useDeleteClientMutation } = clientApi;
