import Avatar from 'components/common/Avatar';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Icon, Text, VStack } from '@chakra-ui/react';

import { FiLogOut } from 'react-icons/fi';
import { useGetUserSelfQuery } from 'services/api/user';
import { clearSession, getUserSession } from 'utils/sessionStorage';

export const UserInfo: React.FC = () => {
  const userInfo = getUserSession();
  const userSelfQuery = useGetUserSelfQuery();

  const navigate = useNavigate();

  return (
    <VStack alignItems="flex-start">
      <Button
        variant="link"
        mb={4}
        onClick={() => {
          clearSession();
          navigate('/');
        }}
        colorScheme="black"
      >
        <Icon as={FiLogOut} boxSize="1em" color="black" mr="1em" cursor="pointer" onClick={() => navigate('/dashboard')} />
        <span>Logout</span>
      </Button>

      <Flex align="flex-start">
        <Avatar name={userInfo?.given_name} logo={userInfo?.picture} boxSize="40px" borderRadius="20px" size="sm" />

        <Box ml={3}>
          <Text fontWeight="bold">{userInfo?.given_name}</Text>
          <Text fontSize="sm">{userInfo?.email}</Text>
          {userSelfQuery?.isSuccess && (
            <Text fontSize="sm">
              <Text fontWeight="bold" as="span">
                Subscription:
              </Text>{' '}
              {userSelfQuery.data.plan_tier}
            </Text>
          )}
        </Box>
      </Flex>
    </VStack>
  );
};
