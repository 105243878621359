import {
  Box,
  ButtonProps,
  Flex,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import Button from 'components/common/Button';
import { AddIcon, InfoOutlineIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { useAddClientMutation } from 'services/api/client';
import { ClientFormType } from 'types';
import { clientIndustries } from 'utils/clients';

const GenerateTextAreaField = ({
  label,
  placeholder,
  isRequired,
  value,
  name = '',
  onChange,
  tabIndex,
}: {
  label: string;
  placeholder: string;
  isRequired: boolean;
  value: string;
  name?: string;
  tabIndex?: number;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  return (
    <>
      <FormLabel m={0}>
        <HStack justifyContent="left">
          <Box>{label}</Box>
          <Tooltip label={`Eg: ${placeholder}`} aria-label={label} bg="gray.400">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
      </FormLabel>
      <Textarea isRequired={isRequired} value={value} name={name} onChange={onChange} height="2em" data-tabindex={tabIndex} />
    </>
  );
};

const AddNewClientForm = ({ onClose }: { onClose: () => void }) => {
  const toast = useToast();

  const inputFileRef = React.useRef<null | HTMLInputElement>(null);

  const [saveClient, { isLoading: isSaving }] = useAddClientMutation();

  const [tabIndex, setTabIndex] = React.useState(0);
  const [tabIndexErrror, setTabIndexError] = React.useState<{ 0: boolean; 1: boolean }>({ 0: false, 1: false });

  const [formValues, setFormValues] = React.useState<ClientFormType>({
    www: '',
    company: '',
    email: '',
    industry: {
      client_industry: '',
      industry_size: '',
    },
    location: '',
    audience: [],
    competitors: [],
    keywords: [],
    branding: {
      mission: '',
      vision: '',
      positioning: '',
      perception: '',
      tone: '',
      marketing_goals: [],
    },
    logo: null,
  });

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    if (inputFileRef.current) {
      inputFileRef?.current?.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFormValues({ ...formValues, logo: selectedFile });
    }
  };

  const handleFileClear = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.preventDefault();
    setFormValues({ ...formValues, logo: null });
    if (inputFileRef?.current?.value) {
      inputFileRef.current.value = '';
    }
  };

  const handleInvalid = (e: React.FormEvent) => {
    const isInvalid = e.type === 'invalid';
    const tabIndex = (e.target as any).attributes['data-tabindex']?.value;
    const isVisible = (e.target as any).checkVisibility();

    if (isInvalid && tabIndex) {
      setTabIndexError((prev) => ({ ...prev, [tabIndex]: true }));
    }

    if (isInvalid && !isVisible) {
      e.preventDefault();
    }
  };
  React.useEffect(() => {
    if (tabIndexErrror[0]) {
      setTabIndex(0);
    } else if (tabIndexErrror[1]) {
      setTabIndex(1);
    }
  }, [tabIndexErrror]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Put all the fields in a form data object to allow logo upload
    const formData = new FormData();
    formData.append('www', formValues.www as string);
    formData.append('company', formValues.company);
    formData.append('email', formValues.email);
    formData.append('industry', JSON.stringify(formValues.industry));
    formData.append('location', formValues.location);
    formData.append('audience', JSON.stringify(formValues.audience));
    formData.append('competitors', JSON.stringify(formValues.competitors));
    formData.append('keywords', JSON.stringify(formValues.keywords));
    formData.append('branding', JSON.stringify(formValues.branding));
    if (formValues.logo) {
      formData.append('logo', formValues.logo as Blob, formValues.logo?.name);
    }

    const result = await saveClient(formData).unwrap();
    if (result) {
      toast({
        title: 'Client saved successfully',
        duration: 1000,
        isClosable: true,
      });
      // Close the modal
      onClose();
    } else {
      toast({
        title: 'Client not saved',
        description: 'Error occured during saving. Check logs!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} onInvalid={handleInvalid}>
      <Flex direction="column">
        {/* Part 1: Top Form Elements: client name, email address, and logo upload */}
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Box width="70%">
            <InputGroup>
              <InputRightElement>
                <Tooltip label="Eg: www.johndoe.com" aria-label="A tooltip" bg="gray.400">
                  <InfoOutlineIcon />
                </Tooltip>
              </InputRightElement>
              <Input
                placeholder="Client website"
                mb={4}
                isRequired={false}
                value={formValues.www}
                onChange={(e) => {
                  setFormValues({ ...formValues, www: e.target.value });
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputRightElement>
                <Tooltip label="Eg: Acme Corp" aria-label="A tooltip" bg="gray.400">
                  <InfoOutlineIcon />
                </Tooltip>
              </InputRightElement>
              <Input
                placeholder="Company Name"
                mb={4}
                isRequired
                value={formValues.company}
                onChange={(e) => {
                  setFormValues({ ...formValues, company: e.target.value });
                }}
              />
            </InputGroup>

            <InputGroup>
              <InputRightElement>
                <Tooltip label="Eg: contact@acmecorp.com" aria-label="A tooltip" bg="gray.400">
                  <InfoOutlineIcon />
                </Tooltip>
              </InputRightElement>
              <Input
                placeholder="Email Address"
                type="email"
                mb={4}
                isRequired
                value={formValues.email}
                onChange={(e) => {
                  setFormValues({ ...formValues, email: e.target.value });
                }}
              />
            </InputGroup>
          </Box>
          <Box>
            <Input placeholder="SelectLogo" type="file" onChange={handleFileChange} ref={inputFileRef} visibility="hidden" display="none" name="logo" />

            {!formValues.logo ? (
              <VStack onClick={onBtnClick} backgroundColor="purple.200" cursor="pointer" borderRadius="10" p={2} width="36">
                <AddIcon boxSize={16} />
                <Text align="center" fontSize="xs">
                  Add Client Logo
                </Text>
              </VStack>
            ) : (
              <VStack position="relative" padding={2} width="36" borderWidth="1px" borderRadius="10">
                <SmallCloseIcon onClick={handleFileClear} position="absolute" boxSize={4} right={0} top={0} />
                <Image src={URL.createObjectURL(formValues.logo)} alt="client logo" boxSize={20} onClick={onBtnClick} h="auto" w="100%" />
              </VStack>
            )}
          </Box>
        </HStack>

        <Tabs isFitted position="relative" index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <TabList>
            <Tab {...(tabIndexErrror[0] ? { color: 'red' } : {})}>General</Tab>
            <Tab {...(tabIndexErrror[1] ? { color: 'red' } : {})}>Branding</Tab>
          </TabList>
          <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
          <TabPanels>
            <TabPanel>
              <VStack alignItems="stretch">
                <HStack justifyContent="space-between" alignItems="start">
                  <Box width="49%">
                    <FormLabel m={0}>Client's Industry</FormLabel>
                    <Select
                      placeholder="Select Industry"
                      isRequired
                      name="client_industry"
                      data-tabIndex={0}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          industry: {
                            ...formValues.industry,
                            client_industry: e.target.value,
                          },
                        });
                      }}
                    >
                      {clientIndustries.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Industry Size"
                      placeholder="250-500 employees"
                      isRequired
                      value={formValues.industry.industry_size}
                      name="industry_size"
                      tabIndex={0}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          industry: {
                            ...formValues.industry,
                            industry_size: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                </HStack>

                <HStack justifyContent="space-between" alignItems="start">
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Location"
                      placeholder="London, United Kingdom"
                      isRequired
                      value={formValues.location}
                      name="location"
                      tabIndex={0}
                      onChange={(e) => {
                        setFormValues({ ...formValues, location: e.target.value });
                      }}
                    />
                  </Box>
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Target Audience"
                      placeholder="Professionals aged 30-45 in the finance sector"
                      isRequired
                      value={formValues.audience?.[0]}
                      name="audience"
                      tabIndex={0}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          audience: [e.target.value],
                        });
                      }}
                    />
                  </Box>
                </HStack>
                <HStack justifyContent="space-between" alignItems="start">
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Key Competitors"
                      placeholder="Competitor 1, Competitor 2, Competitor 3"
                      isRequired
                      value={formValues.competitors?.[0]}
                      name="competitors"
                      tabIndex={0}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          competitors: [e.target.value],
                        });
                      }}
                    />
                  </Box>
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Keywords"
                      placeholder="Increase business productivity, innovative productivity solutions"
                      isRequired
                      value={formValues.keywords?.[0]}
                      name="keywords"
                      tabIndex={0}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          keywords: [e.target.value],
                        });
                      }}
                    />
                  </Box>
                </HStack>
              </VStack>
            </TabPanel>

            <TabPanel>
              <VStack alignItems="stretch">
                <HStack justifyContent="space-between">
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Mission"
                      placeholder="To deliver cutting-edge solutions that enhance client productivity"
                      isRequired
                      value={formValues.branding.mission}
                      name="mission"
                      tabIndex={1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          branding: {
                            ...formValues.branding,
                            mission: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Vision"
                      placeholder="To be the leading provider of innovative digital solutions globally"
                      isRequired
                      value={formValues?.branding?.vision}
                      name="vision"
                      tabIndex={1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          branding: {
                            ...formValues.branding,
                            vision: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                </HStack>
                <HStack justifyContent="space-between">
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Brand positioning"
                      placeholder="Premium service provider in technology solutions"
                      isRequired
                      value={formValues.branding.positioning}
                      name="positioning"
                      tabIndex={1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          branding: {
                            ...formValues.branding,
                            positioning: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Brand perception"
                      placeholder="Innovative, reliable, customer-focused"
                      isRequired
                      value={formValues.branding.perception}
                      name="perception"
                      tabIndex={1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          branding: {
                            ...formValues.branding,
                            perception: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                </HStack>
                <HStack justifyContent="space-between" alignItems="start">
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Brand Tone"
                      placeholder="Professional, authoritative, approachable"
                      isRequired
                      value={formValues.branding.tone}
                      name="tone"
                      tabIndex={1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          branding: {
                            ...formValues.branding,
                            tone: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                  <Box width="49%">
                    <GenerateTextAreaField
                      label="Marketing Goals"
                      placeholder="Increase brand awareness by 30%; Improve customer retention by 20%"
                      isRequired
                      value={formValues.branding.marketing_goals?.[0]}
                      name="marketing_goals"
                      tabIndex={1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          branding: {
                            ...formValues.branding,
                            marketing_goals: [e.target.value],
                          },
                        });
                      }}
                    />
                  </Box>
                </HStack>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Text fontSize="sm" color="gray.500" mt={2}>
          All fields are mandatory
        </Text>
        <Button mt={4} colorScheme="teal" type="submit" isLoading={isSaving} loadingText="Saving Client" onClick={() => setTabIndexError({ 0: false, 1: false })}>
          Save Client
        </Button>
      </Flex>
    </form>
  );
};

export const AddNewClient = (props: ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        colorScheme="purple"
        {...props}
        onClick={() => {
          onOpen();
        }}
      >
        Add New Client
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false} size="xl">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader>Add New Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddNewClientForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
