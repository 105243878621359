import { BlogTopic, GDocSummary, ProjectType } from 'types';
import { RtkTagTypes, baseApi } from './baseApi';

export const ProjectsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectType[], void>({
      query: () => ({
        url: '/projects',
        method: 'GET',
      }),
      providesTags: [RtkTagTypes.Projects],
    }),
    getProjectSummary: builder.query<GDocSummary, string>({
      query: (clientId) => ({
        url: `/summary/${clientId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 600, // 10 minutes
      providesTags: [RtkTagTypes.ProjectsSummary],
    }),
    getProjectTopics: builder.query<BlogTopic[], GDocSummary | undefined>({
      query: (summary) => ({
        url: `/blog_topics`,
        method: 'POST',
        body: summary,
      }),
      keepUnusedDataFor: 600, // 10 minutes
      providesTags: [RtkTagTypes.ProjectsTopics],
    }),
    getProjectOutline: builder.query<
      string,
      { topic: BlogTopic; keywords: string[]; language: string; clientId: string }
    >({
      query: ({ topic, keywords, language, clientId }) => ({
        url: `/generate_outline`,
        method: 'POST',
        body: { topic, keywords, language },
        headers: { 'client-id': clientId },
        refetchOnMountOrArgChange: true,
      }),
      transformResponse: (response: { content: string }) => {
        return response.content;
      },
      keepUnusedDataFor: 600, // 10 minutes
      providesTags: [RtkTagTypes.ProjectsOutline],
    }),

    getProjectContent: builder.query<
      { content: string; googleDocLink: string; clientId: string } | undefined,
      { topic: BlogTopic; clientId: string }
    >({
      query: ({ topic, clientId }) => ({
        url: `/blog_content`,
        method: 'POST',
        body: topic,
        headers: { 'client-id': clientId },
      }),
    }),
    saveProjectOutline: builder.mutation<
      BlogTopic,
      { topic: BlogTopic; outline: string; keywords: string[]; clientId: string; language: string }
    >({
      query: ({ topic, outline, keywords, clientId, language }) => ({
        url: '/save_outline',
        method: 'POST',
        body: { topic, outline: { content: outline }, keywords, language },
        headers: { 'client-id': clientId },
      }),
      invalidatesTags: [RtkTagTypes.Projects],
    }),
    saveProjectContent: builder.mutation<
      boolean,
      {
        content: string;
        clientId: string;
        topic_id: number;
        topic: BlogTopic;
      }
    >({
      query: ({ content, clientId, topic_id, topic }) => ({
        url: '/projects',
        method: 'POST',
        body: { content, topic },
        headers: { 'client-id': clientId, 'topic-id': topic_id.toString() },
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectSummaryQuery,
  useGetProjectTopicsQuery,
  useGetProjectOutlineQuery,
  useGetProjectContentQuery,
  useSaveProjectOutlineMutation,
  useSaveProjectContentMutation,
} = ProjectsApi;
