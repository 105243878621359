import { Box, ChakraProps, Flex } from '@chakra-ui/react';
import Sidebar from 'components/dashboard/Sidebar';
import React from 'react';

const MainLayout = ({ children, ...props }: React.PropsWithChildren & ChakraProps) => {
  return (
    <Flex overflow="hidden">
      {/* Sidebar */}
      <Box w={{ base: '30%', xl: '20%' }} pb={5} bg="white">
        <Sidebar />
      </Box>

      {/* Main Content */}
      <Box w={{ base: '70%', xl: '80%' }} p={5} {...props}>
        {children}
      </Box>
    </Flex>
  );
};

export default MainLayout;
