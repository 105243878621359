import { Box, Center, Flex, Heading, Link, SimpleGrid, Spinner, Tag, TagLabel, TagRightIcon, Text } from '@chakra-ui/react';
import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';
import React, { useState } from 'react';
import { FaGoogleDrive } from 'react-icons/fa';
import { useGetClientsQuery } from 'services/api/client';
import { useGetProjectsQuery } from 'services/api/projects';

export const Projects = () => {
  const { data: projects, isLoading } = useGetProjectsQuery();
  const { data: clients } = useGetClientsQuery();

  const getClient = (clientId: string) => clients?.find((client) => client.id === clientId);
  const limitIncrement = 4;
  const [limit, setLimit] = useState(limitIncrement);
  return (
    <Box mt={8}>
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h2" color="primary" opacity="50%" paddingX={8}>
          Recent Projects
        </Heading>
      </Flex>
      <SimpleGrid columns={{ base: 3, xl: 4 }} spacing={4}>
        {/* Map through the projects and create a ProjectCard for each */}
        {projects?.slice(0, limit).map((project) => {
          const { updated, topic_name, topic_id, gdrive_blog_url } = project;
          const readableDate = new Date(Number(updated) * 1000);
          const client = getClient(project.client_id);
          return (
            // Replace with ProjectCard component
            <Flex key={`${topic_id}${gdrive_blog_url}`} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} gap={4} justifyContent="space-between" direction="column" position="relative">
              <Heading as="h4" size="md" colorScheme="blackAlpha">
                {topic_name}
              </Heading>

              <Flex direction="column" justifyContent="space-between" gap={4}>
                {client && (
                  <Flex align="center">
                    <span>By</span>
                    <Avatar logo={client.logo} name={client.name} boxSize="20px" marginX={2} size="sm" />
                    <span>{client.name}</span>
                  </Flex>
                )}
                {readableDate && (
                  <Text>
                    {`Edited: ${readableDate.toLocaleDateString()} at 
                    ${readableDate.toLocaleTimeString([], {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })}`}
                  </Text>
                )}

                <Link href={gdrive_blog_url} isExternal>
                  <Tag colorScheme="purple" borderRadius="full">
                    <TagLabel>View File in Google Drive</TagLabel>
                    <TagRightIcon as={FaGoogleDrive} />
                  </Tag>
                </Link>
              </Flex>
            </Flex>
          );
        })}
      </SimpleGrid>
      {(projects?.length ?? 0) > limit && (
        <Center>
          <Button mt={4} onClick={() => setLimit(limit + limitIncrement)} colorScheme="teal">
            Show more
          </Button>
        </Center>
      )}
      {isLoading && (
        <Box mb={4}>
          <Spinner /> Loading projects ...
        </Box>
      )}
    </Box>
  );
};
