import { Box, Container, Grid, GridItem, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { GoogleLoginButton } from 'components/login/GoogleLoginButton';
import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { getSessionid, getUserSession } from 'utils/sessionStorage';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const session = getSessionid();
  const userInfo = getUserSession();

  useEffect(() => {
    if (session && userInfo) {
      navigate('/dashboard');
    }
  }, [session, userInfo, navigate]);

  return (
    <Container centerContent maxW="100vw" w="100%" h="100vh" bg="white" p="0">
      <Grid templateColumns={'repeat(6, 1fr)'} gap={0} w="100%" h="100%">
        <GridItem colSpan={1} display={{ base: 'none', md: 'block' }}>
          <Image src="/svgs/yai-logo.svg" m="2em" alt="YAi" />
        </GridItem>
        <GridItem colSpan={{ md: 3, base: 6 }} display="flex" flexDirection="column" justifyContent="center">
          <VStack spacing={4}>
            <Box textAlign="center">
              <Heading color="primary" mb={2} as="h1" size="2xl">
                Welcome to YAi
              </Heading>
              <Text fontSize="lg" color="black">
                Let’s create amazing blog content
              </Text>
            </Box>
            <GoogleLoginButton />
          </VStack>
        </GridItem>
        <GridItem colSpan={2} display={{ base: 'none', md: 'block' }}>
          <Box backgroundSize="cover" backgroundPosition="center" backgroundImage="/svgs/laptop-girl.svg" height="100%"></Box>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default LoginPage;
