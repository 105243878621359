import { HStack, Heading, Tag, TagCloseButton, TagLabel, VStack, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import Button from 'components/common/Button';
import React, { KeyboardEvent, useState } from 'react';

interface KeywordsProps {
  setLanguage: (code: string) => void;
  saveKeywords: (keywords: string[]) => void;
  savedKeywords: string[];
  handleGoBack: () => void;
  handleGoForward: () => void;
}

const Keywords = ({ setLanguage, savedKeywords, saveKeywords, handleGoBack, handleGoForward }: KeywordsProps) => {
  const languageOptions = [
    { country: 'United Kingdom', language: 'English', code: 'en-GB' },
    { country: 'United States', language: 'English', code: 'en-US' },
  ];
  const [inputValue, setInputValue] = useState<string>('');
  const [keywords, setKeywords] = useState<string[]>(savedKeywords);
  const [radioValue, setRadioValue] = useState<string>(languageOptions[0].code);

  const handleConfirmKeywords = () => {
    saveKeywords(keywords);
    setLanguage(radioValue);
    handleGoForward();
  };

  const addKeyword = () => {
    if (inputValue) {
      // explode by comma and remove any spaces
      const inputs = inputValue.split(',').map((input) => input.trim());
      const newValues = new Set([...keywords, ...inputs]);
      setKeywords([...newValues]);
      setInputValue('');
    }
  };

  return (
    <VStack spacing={6} align="stretch">
      <Heading as="h2" size="md">
        Add Keywords and language
      </Heading>

      {/* Show list of tags if available */}
      <HStack spacing={4}>
        <Input
          placeholder="Enter a keyword + Enter to add to list"
          type="text"
          value={inputValue}
          bg="white"
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={({ code }: KeyboardEvent<HTMLInputElement>) => {
            if (code === 'Enter') {
              addKeyword();
            }
          }}
        />{' '}
        <Button colorScheme="purple" size="sm" onClick={addKeyword}>
          Add Tag
        </Button>
      </HStack>

      <HStack spacing={4}>
        {keywords.map((value, index) => (
          <Tag size="lg" key={value} variant="solid" colorScheme="purple">
            <TagLabel>{value}</TagLabel>
            <TagCloseButton
              onClick={() => {
                keywords.splice(index, 1);
                const newValues = [...keywords];
                setKeywords(newValues);
              }}
            />
          </Tag>
        ))}
      </HStack>
      <h2>Blog language</h2>
      <HStack spacing={4}>
        <RadioGroup defaultChecked={true} defaultValue={radioValue} onChange={setRadioValue}>
          <Stack spacing={5} direction="row">
            {languageOptions.map((option, index) => {
              return (
                <Radio value={option.code} colorScheme="purple" key={index}>
                  {option.country} - {option.language}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </HStack>

      <HStack justifyContent="flex-start">
        <Button onClick={handleGoBack} variant="outline" colorScheme="purple">
          Back to Topics
        </Button>
        {keywords.length && (
          <Button colorScheme="purple" onClick={handleConfirmKeywords}>
            Confirm keywords
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

export default Keywords;
