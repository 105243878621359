import { Box, Spinner, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';

import { store } from 'app/store';
import { AddNewClient } from 'components/dashboard/clients/AddNewClient';
import { baseApi, RtkTagTypes } from 'services/api/baseApi';
import { useGetClientsQuery } from 'services/api/client';

export const ClientsList = () => {
  const { data: clients, isLoading, isFetching } = useGetClientsQuery();
  const limitIncrement = 4;
  const [limit, setLimit] = useState(limitIncrement);
  const navigate = useNavigate();

  return (
    <>
      <Box w="100%">
        <AddNewClient w="200px" mb={4} />
      </Box>

      {(isLoading || isFetching) && (
        <Box mb={4}>
          <Spinner /> Loading clients ...
        </Box>
      )}

      <Text fontWeight="bold">My Clients</Text>
      {clients?.slice(0, limit).map(({ id, name, logo }) => (
        <Button
          variant="link"
          colorScheme="black"
          key={id}
          _hover={{ textDecoration: 'none' }}
          my={1}
          title="Create a new blog project"
          onClick={() => {
            store.dispatch(baseApi.util.invalidateTags([RtkTagTypes.ProjectsSummary, RtkTagTypes.ProjectsTopics]));
            navigate(`/blog_create/${id}`);
          }}
        >
          <Avatar logo={logo} name={name} boxSize="6" size="xs" />
          <Box _hover={{ textDecoration: 'underline' }} fontWeight={'bold'}>
            {name}
          </Box>
        </Button>
      ))}

      {(clients?.length ?? 0) > limit && (
        <Button mt={4} onClick={() => setLimit(limit + limitIncrement)}>
          Show more
        </Button>
      )}
    </>
  );
};
