import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { REACT_APP_SAFE_MODE } from 'utils/consts';
import { store } from './app/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWrapper = REACT_APP_SAFE_MODE ? React.StrictMode : 'div';

const theme = extendTheme({
  colors: {
    black: '#11041a',
    primary: '#8c1eca',
    white: '#ffffff',
    error: '#f87171',
    green: '#14c8be',
    grey: '#c2c2c2',
    textGrey: '#696969',
    buttonBgV2: '#b24ded',
    buttonBgV3: '#7116a7',
    buttonBgV4: '#14c8be',
    buttonBgV5: '#56dcd4',
    buttonBgV6: '#12b2aa',
  },
});

root.render(
  <AppWrapper>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </AppWrapper>,
);
