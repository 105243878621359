import { Box, Center, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Text, useToast } from '@chakra-ui/react';

import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteClientMutation, useGetClientsQuery } from 'services/api/client';
import { AddNewClient } from './clients/AddNewClient';

import { CloseIcon } from '@chakra-ui/icons';
import { ClientType } from 'types';

import { store } from 'app/store';
import { baseApi, RtkTagTypes } from 'services/api/baseApi';

export const ClientsLists = () => {
  const toast = useToast();
  const { data: clients, isLoading, isFetching } = useGetClientsQuery();

  const [deleteClient] = useDeleteClientMutation();
  const [selectedClient, setSelectedClient] = useState<null | ClientType>(null);

  const navigate = useNavigate();
  const limitIncrement = 4;
  const [limit, setLimit] = useState(limitIncrement);

  const handleDelete = async (client: ClientType) => {
    setSelectedClient(null);
    try {
      await deleteClient(client.id).unwrap();
      toast({
        title: 'Client deleted successfully',
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Client not deleted',
        description: 'Error occured during saving. Check logs!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h2" color="primary" opacity="50%" paddingX={8}>
          My Clients
        </Heading>
        <AddNewClient />
      </Flex>
      <SimpleGrid columns={{ base: 3, xl: 4 }} spacing={4}>
        {/* Map through the clients and create a ClientCard for each */}
        {clients?.slice(0, limit).map((client) => {
          const { id, name, logo, short_description } = client;
          return (
            <Flex key={id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} justifyContent="space-between" direction="column" position="relative">
              <CloseIcon cursor="pointer" position="absolute" top={3} right={3} onClick={() => setSelectedClient(client)} />

              <Flex align="center" mb={2}>
                <Avatar logo={logo} name={name} boxSize="50px" size="md" />
                <Heading as="h3" size="md">
                  {name}
                </Heading>
              </Flex>
              <Text fontSize="sm" align={'left'}>
                {short_description}
              </Text>
              <Flex justify="space-between" mt={4}>
                <Button
                  colorScheme="teal"
                  size="sm"
                  onClick={() => {
                    store.dispatch(baseApi.util.invalidateTags([RtkTagTypes.ProjectsSummary, RtkTagTypes.ProjectsTopics]));
                    navigate(`/blog_create/${id}`);
                  }}
                >
                  Create a new project
                </Button>
              </Flex>
            </Flex>
          );
        })}
        {selectedClient && (
          <Modal isOpen onClose={() => setSelectedClient(null)} isCentered>
            <ModalOverlay backdropFilter="blur(10px) " />
            <ModalContent>
              <ModalHeader>Delete client '{selectedClient.name}'?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb="1em">Proceeding with this action will permanently delete all data, and recovery will not be possible.</Text>
                <Text fontWeight="bolder">Continue with deletion?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={() => setSelectedClient(null)}>
                  No
                </Button>
                <Button colorScheme="green" onClick={() => handleDelete(selectedClient)}>
                  Yes
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </SimpleGrid>
      {(clients?.length ?? 0) > limit && (
        <Center>
          <Button mt={4} onClick={() => setLimit(limit + limitIncrement)} colorScheme="teal">
            Show more
          </Button>
        </Center>
      )}
      {(isLoading || isFetching) && (
        <Box mb={4}>
          <Spinner /> Loading clients ...
        </Box>
      )}
    </Box>
  );
};
