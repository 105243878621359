import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Heading, Icon, Step, StepIcon, StepIndicator, StepNumber, StepStatus, StepTitle, Stepper, VStack, useSteps } from '@chakra-ui/react';
import Approval from 'components/blog/Approval';
import Keywords from 'components/blog/Keywords';
import Outline from 'components/blog/Outline';
import Topic from 'components/blog/Topic';
import MainLayout from 'components/layout/MainLayout';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogTopic } from 'types';
import { getSessionid } from 'utils/sessionStorage';

const BlogCreate: React.FC = () => {
  const navigate = useNavigate();
  const session = getSessionid();

  const { clientId } = useParams<{ clientId: string }>();
  const [keywords, setKeywords] = useState<string[]>([]);
  const [languageCode, setLanguageCode] = useState<string>();
  const [selectedTopic, setSelectedTopic] = useState<BlogTopic>();

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
    if (!clientId) {
      navigate('/dashboard');
    }
  }, [session, clientId, navigate]);

  const steps = [{ title: 'Blog Topics' }, { title: 'Keywords' }, { title: 'Create Outline' }, { title: 'Final Approval' }];

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const handleGoBack = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    } else {
      navigate('/dashboard');
    }
  };
  const handleGoForward = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  if (!clientId) {
    navigate('/dashboard');
  }

  return (
    <MainLayout>
      <VStack alignItems="flex-start" height="100vh">
        <VStack alignItems="flex-start" spacing={6} paddingX={8} mb={8}>
          <Heading as="h2" color="primary" opacity="50%" data-testid="blog-create-h2">
            Create New Blog
          </Heading>

          <Heading as="h5" size="xs" data-testid="blog-create-h5">
            Edit blog according to your prefrences
          </Heading>
          <Stepper index={activeStep} colorScheme="purple" size={{ base: 'sm' }} alignSelf="flex-start" data-testid="blog-create-progress-bar">
            {steps.map((step, index) => (
              <Step key={uniqueId()}>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                </Box>
                {index < steps.length - 1 && <Icon as={ChevronRightIcon} boxSize={{ base: '1em', md: '1.5em' }} mr={{ base: '1em', md: '1em' }} display={{ base: 'none', lg: 'flex' }} />}
              </Step>
            ))}
          </Stepper>
        </VStack>
        <Box bg={'gray.50'} p={8} pb={20} overflowY="auto" width="100%" height="100%" data-testid="blog-create-body">
          {activeStep === 1 && <Topic clientId={clientId!} setSelectedTopic={setSelectedTopic} handleGoBack={handleGoBack} handleGoForward={handleGoForward} />}
          {activeStep === 2 && <Keywords savedKeywords={keywords} setLanguage={setLanguageCode} saveKeywords={setKeywords} handleGoBack={handleGoBack} handleGoForward={handleGoForward} />}
          {activeStep === 3 && clientId && (
            <Outline
              clientId={clientId}
              language={languageCode!}
              keywords={keywords}
              selectedTopic={selectedTopic!}
              setSelectedTopic={setSelectedTopic}
              handleGoBack={handleGoBack}
              handleGoForward={handleGoForward}
            />
          )}
          {activeStep === 4 && clientId && <Approval topic={selectedTopic!} clientId={clientId} handleGoBack={handleGoBack} />}
        </Box>
      </VStack>
    </MainLayout>
  );
};

export default BlogCreate;
