import { ClientsLists } from 'components/dashboard/Clients';
import { Projects } from 'components/dashboard/Projects';
import MainLayout from 'components/layout/MainLayout';
import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { getSessionid } from 'utils/sessionStorage';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const session = getSessionid();

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
  }, [session, navigate]);

  if (!session) {
    return <p>No user session. Redirecting to Login page!</p>;
  }

  return (
    <MainLayout overflowY="auto">
      {/* My Clients Section */}
      <ClientsLists />
      {/* Recent Projects Section */}
      <Projects />
    </MainLayout>
  );
};
export default Dashboard;
